
import { defineComponent } from 'vue';
import router from '@/router';

export default defineComponent({
  name: 'SemPermissao',
  components: {

  },
  setup() {
    function voltar() {
      router.push({ name: 'MinhaDashboard' });
    }

    return {
      voltar,
    };
  },
});
