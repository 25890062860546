
import {
  defineComponent, reactive, ref, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import CampoTelefone from '@/core/components/Tela/CampoTelefone.vue';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import SelecionarPerfilUsuario from '@/components/MeuSistema/Usuarios/SelecionarPerfilUsuario.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import RegistroAtividade from '@/components/MeuSistema/Usuarios/RegistroAtividade.vue';
import Card from '@/core/components/Tela/Card.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import ServicoUsuario from '@/servicos/MeuSistema/ServicoUsuario';
import { IUsuario, IUsuarioEmpresa, IUsuarioPerfilUsuario } from '@/models/Entidades/MeuSistema/Usuarios/IUsuario';
import CampoSenha from '@/core/components/Tela/CampoSenha.vue';

export default defineComponent({
  name: 'UsuarioModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    CampoTelefone,
    RequisicaoModal,
    RegistroAtividade,
    SelecionarEmpresa,
    SelecionarEmpresaCadastroCompartilhado,
    SelecionarPerfilUsuario,
    CampoSenha,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, defineEmpresasSelecionadasCadastroCompartilhado, obterPermissoes, apresentarMensagemAlerta, preencherEmpresasComEstrategiaPermissaoDados,
      preencherPermissoesDados, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso, verificaConceitoParaApresentarEmpresas,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, defineTextoPadraoBotoes, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoUsuario = new ServicoUsuario();
    telaBase.identificadorRecurso = 'CADASTRO_USUARIOS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_USUARIOS';

    const refRegistroAtividade = ref<InstanceType<typeof RegistroAtividade>>();

    const state = reactive({
      usuario: {} as IUsuario,
      listaArquivo: [],
      perfilUsuarioDb: 0,
      senhaDb: '',
      carrengadoImagem: false,
      apresentarUnicoPerfil: false,
      limparDadosRegistroAtividade: false,
    });

    async function preencherPerfilUsuarioPorEmpresa() {
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          if (!state.usuario.perfis.some((c) => c.codigoEmpresa === codigoEmpresa)) {
            const perfilUsuario:IUsuarioPerfilUsuario = {} as IUsuarioPerfilUsuario;
            perfilUsuario.codigo = 0;
            perfilUsuario.codigoEmpresa = codigoEmpresa;
            perfilUsuario.codigoUsuario = state.usuario.codigo;
            perfilUsuario.codigoPerfilUsuario = 0;
            state.usuario.perfis.push(perfilUsuario);
          }
        });
      }
    }

    function objetoInicial() {
      telaBase.empresasSelecionadas = [];
      state.senhaDb = '';
      state.perfilUsuarioDb = 0;
      state.apresentarUnicoPerfil = false;
      state.usuario = { codigo: 0, ativo: true } as IUsuario;
      state.usuario.tipo = 4;
      state.usuario.senha = '';
      state.usuario.empresas = [] as IUsuarioEmpresa[];
      state.usuario.perfis = [] as IUsuarioPerfilUsuario[];
      if (refRegistroAtividade.value !== undefined) {
        refRegistroAtividade.value.limparDados();
      }
    }

    async function atualizarSelecaoEmpresas() {
      await preencherPerfilUsuarioPorEmpresa();
      const removerIndexPerfis: Array<number> = [];
      // Verifica se tem perfis com empresas removidas
      for (let i = 0; i < state.usuario.perfis.length; (i += 1)) {
        if (!telaBase.empresasSelecionadas.includes(state.usuario.perfis[i].codigoEmpresa)) {
          removerIndexPerfis.push(i);
        }
      }

      // Remove perfis que as empresas foram removidas
      removerIndexPerfis.forEach(async (index) => {
        if (index !== -1) {
          state.usuario.perfis.splice(index, 1);
        }
      });
    }

    async function enviarImagem(arquivo:any) {
      state.carrengadoImagem = true;
      const retornoArquivo = await servicoUsuario.enviarImagem(arquivo, false);
      if (retornoArquivo.status === EStatusRetornoRequisicao.Sucesso) {
        state.usuario.imagem = retornoArquivo.linkArquivo;
      } else {
        apresentarMensagemAlerta(retornoArquivo.mensagem);
      }
      state.carrengadoImagem = false;
    }

    async function removerImagem() {
      state.carrengadoImagem = true;
      const retornoArquivo = await servicoUsuario.removerImagem(state.usuario.imagem);
      if (retornoArquivo.status === EStatusRetornoRequisicao.Sucesso) {
        state.usuario.imagem = '';
      } else if (retornoArquivo.status === EStatusRetornoRequisicao.Alerta) {
        state.usuario.imagem = '';
        apresentarMensagemAlerta(retornoArquivo.mensagem);
      }
      state.carrengadoImagem = false;
    }

    async function preparaComportamentoTela() {
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        state.usuario = await servicoUsuario.obter(props.operacao.codigoRegistro);
        state.senhaDb = state.usuario.senha;
        state.perfilUsuarioDb = state.usuario.perfis[0].codigoPerfilUsuario;
        state.usuario.senha = '';
        state.usuario.confirmacaoSenha = '';
        telaBase.empresasSelecionadas = [];
        state.usuario.empresas.forEach((usuarioEmpresa) => {
          telaBase.empresasSelecionadas.push(usuarioEmpresa.codigoEmpresa);
        });
      } else {
        await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
      }
      await atualizarSelecaoEmpresas();
      if (telaBase.empresasSelecionadas.length === 1) {
        state.apresentarUnicoPerfil = true;
      }
    }

    function preparaPersistenciaEmpresas() {
      const usuarioEmpresas : IUsuarioEmpresa[] = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const empresaExistente = state.usuario.empresas.find((c) => c.codigoEmpresa === codigoEmpresa);
          if (empresaExistente !== undefined) {
            usuarioEmpresas.push(empresaExistente);
          } else {
            const usuarioEmpresa: IUsuarioEmpresa = { codigo: 0, codigoUsuario: state.usuario.codigo, codigoEmpresa };
            usuarioEmpresas.push(usuarioEmpresa);
          }
        });
      }
      state.usuario.empresas = usuarioEmpresas;
    }

    function validarCampos() {
      if (state.usuario.senha !== state.usuario.confirmacaoSenha) {
        apresentarMensagemAlerta('A senha e a senha de confimação não estão iguais!');
        return false;
      }

      if (state.usuario.senha !== '') {
        let qtdMinuscula = 0;
        for (let i = 0; i < state.usuario.senha.length; i += 1) {
          if (state.usuario.senha[i].match(/[a-z]/)) {
            qtdMinuscula += 1;
          }
        }
        if (qtdMinuscula === 0) {
          apresentarMensagemAlerta('A senha deve ter pelo menos uma letra minúscula!');
          return false;
        }

        let qtdMaiuscula = 0;
        for (let i = 0; i < state.usuario.senha.length; i += 1) {
          if (state.usuario.senha[i].match(/[A-Z]/)) {
            qtdMaiuscula += 1;
          }
        }
        if (qtdMaiuscula === 0) {
          apresentarMensagemAlerta('A senha deve ter pelo menos uma letra maiuscula!');
          return false;
        }

        let qtdNumero = 0;
        for (let i = 0; i < state.usuario.senha.length; i += 1) {
          if (state.usuario.senha[i].match(/[0-9]/)) {
            qtdNumero += 1;
          }
        }
        if (qtdNumero === 0) {
          apresentarMensagemAlerta('A senha deve ter pelo menos um número!');
          return false;
        }

        let qtdSimbolos = 0;
        for (let i = 0; i < state.usuario.senha.length; i += 1) {
          if (state.usuario.senha[i].match(/\W/)) {
            qtdSimbolos += 1;
          }
        }
        if (qtdSimbolos === 0) {
          apresentarMensagemAlerta('A senha deve ter pelo menos um caractere especial!');
          return false;
        }
      } else if (state.usuario.senha === '' && state.senhaDb === '') {
        apresentarMensagemAlerta('A senha deve ser informada!');
        return false;
      }
      return true;
    }

    async function salvar(salvarNovo: boolean) {
      if (state.senhaDb !== state.usuario.senha) {
        const validacao = validarCampos();
        if (!validacao) return;
      }

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      preparaPersistenciaEmpresas();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        apresentarBarraProgresso('Aguarde por favor, estamos cadastrando o usuário...');
        retorno = await servicoUsuario.incluir(state.usuario);
        state.usuario.codigo = retorno.codigoRegistro;
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        if (state.usuario.senha === '') {
          state.usuario.senha = state.senhaDb;
        }
        apresentarBarraProgresso('Aguarde por favor, estamos salvando as informações do usuário...');
        retorno = await servicoUsuario.alterar(state.usuario);
      }

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (state.perfilUsuarioDb !== state.usuario.perfis[0].codigoPerfilUsuario) {
          const retornoAlteracaoPerfil = await servicoUsuario.atualizarPerfilUsuarioComPermissoes(state.usuario.codigo, state.usuario.perfis[0].codigoEmpresa, state.usuario.perfis[0].codigoPerfilUsuario);
          if (retornoAlteracaoPerfil.status !== EStatusRetornoRequisicao.Sucesso) {
            ocultarBarraProgresso();
            apresentarRetornoRequisicao(retorno);
            return;
          }
        }
        ocultarBarraProgresso();

        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
          await preparaComportamentoTela();
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        ocultarBarraProgresso();
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);
        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        await preparaComportamentoTela();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        verificaConceitoParaApresentarEmpresas();
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      EPermissaoDados,
      atualizarSelecaoEmpresas,
      enviarImagem,
      removerImagem,
      preencherPerfilUsuarioPorEmpresa,
      refRegistroAtividade,
    };
  },
});
